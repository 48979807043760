<template>
  <div class="layout-footer">
    <div class="footer-logo-container">
      <img
        id="footer-logo"
        src="./assets/sass/layout/images/logo-light.png"
        alt="atlantis-layout"
      />
      <span class="app-name">Home Expenses</span>
    </div>
    <span class="copyright">&#169; 2021</span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    colorScheme: String,
  },
});
</script>
